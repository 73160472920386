var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.userId)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("#"+_vm._s(_vm.userId))])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"hide-details":"auto","label":"Usuário","error-messages":errors},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}])})],1)],1),_c('v-row',[(!_vm.userId && !_vm.loading)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"hide-details":"auto","label":"Senha","type":"password","error-messages":errors},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,false,224969599)})],1):_vm._e(),_c('v-col',{staticClass:"mt-6",attrs:{"md":"2"}},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"color":"info","disabled":_vm.loading,"true-value":true,"false-value":false,"label":"Ativo","hide-details":"auto"},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}})],1)],1),_c('v-row',{staticClass:"mb-0 mt-7"},[_c('v-col',{staticClass:"text-left"},[_c('span',{staticClass:"display-1 font-weight-bold"},[_vm._v("Grupos")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.roles),function(role){return _c('v-col',{key:role.id,staticClass:"pb-0 pt-0",attrs:{"md":"6"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","multiple":"","label":role.name,"value":role.id,"error":errors.length > 0},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}})],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(errors.length > 0)?_c('div',{staticClass:"v-messages theme--light error--text mt-2"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],1)]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }