<template>
  <v-dialog
    v-model="show"
    max-width="600px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="userId"
            class="body-2"
          >
            <i>#{{ userId }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="user.name"
                  :disabled="loading"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="username"
              >
                <v-text-field
                  v-model="user.username"
                  :disabled="loading"
                  hide-details="auto"
                  label="Usuário"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="!userId && !loading"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="password"
              >
                <v-text-field
                  v-model="user.password"
                  :disabled="loading"
                  hide-details="auto"
                  label="Senha"
                  type="password"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col
              md="2"
              class="mt-6"
            >
              <v-switch
                v-model="user.active"
                color="info"
                class="mt-0 pt-0"
                :disabled="loading"
                :true-value="true"
                :false-value="false"
                label="Ativo"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row class="mb-0 mt-7">
            <v-col class="text-left">
              <span class="display-1 font-weight-bold">Grupos</span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="roles"
              >
                <v-row no-gutters>
                  <v-col
                    v-for="role in roles"
                    md="6"
                    class="pb-0 pt-0"
                    :key="role.id"
                  >
                    <v-checkbox
                      v-model="user.roles"
                      class="mt-0"
                      hide-details="auto"
                      multiple
                      :label="role.name"
                      :value="role.id"
                      :error="errors.length > 0"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages theme--light error--text mt-2"
                    >
                      {{ errors[0] }}
                    </div>
                  </v-col>
                </v-row>
              </validation-provider>
            </v-col>
          </v-row>

        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            v-text="'mdi-content-save'"
            left
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import usersApi from '@/api/users'
  import rolesApi from '@/api/roles'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      userId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        roles: [],
        user: {
          active: true,
          roles: []
        },
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      title () {
        return this.userId ? 'Editar' : 'Adicionar'
      }
    },

    mounted () {
      this.loadRoles()

      if (this.userId) {
        this.loadUser()
      }
    },

    methods: {

      async loadUser () {
        try {
          this.loading = true

          const response = await usersApi.get(this.userId)

          const user = { ...response.data.user }
          
          const roles = user.roles.map((r) => r.id)
          user.roles = roles

          this.user = user
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadRoles () {
        try {
          this.loading = true

          const response = await rolesApi.list()

          this.roles = response.data.roles
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          const user = { ...this.user }

          if (this.userId) {
            await usersApi.update(this.userId, user)
          } else {
            await usersApi.insert(user)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.userId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
